var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Dynamic"}},[_c('div',{staticClass:"Dynamic_box"},[_c('div',{staticClass:"layer"}),_c('div',{staticClass:"Dynamic_out"},[_c('div',{staticClass:"container",class:_vm.TranSitionSty,style:(_vm.LeftSty)},_vm._l((_vm.DetailArr),function(item,index){return _c('div',{key:index,staticClass:"contItem",style:('width:' +
            _vm.WidTh +
            'px;margin-left:' +
            _vm.MarGin +
            'px;margin-right:' +
            _vm.MarGin +
            'px'),attrs:{"src":_vm._f("GuoLvTwo")(item.lv)}},[_c('div',{staticClass:"cont_pic"},[_c('img',{attrs:{"src":item.cover,"alt":""}})]),_c('div',{staticClass:"item-bottom",class:`bg-${item.lv} show-${item.lv}`})])}),0)]),_c('div',{staticClass:"shu"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }