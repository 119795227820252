<template>
	<div id="Goods">
		<div class="Goods_box">
<!--			<div class="Goods_nav">-->
<!--				<p :class="{'active' : Switch == '2'}" @click="Switch = '2'">箱内物品</p>-->
<!--				<p :class="{'active' : Switch == '1'}" @click="Switch = '1'">最近掉落</p>-->

<!--			</div>-->
			<van-tabs v-model="active" type="card">
				<div class="Explosive">
					<p><img src="../../assets/images/open/yellow.png" alt="">:{{yellow.toFixed(2)}}%</p>
					<p><img src="../../assets/images/open/red.png" alt="">:{{red.toFixed(2)}}%</p>
					<p><img src="../../assets/images/open/prople.png" alt="">:{{purple.toFixed(2)}}%</p>
					<p><img src="../../assets/images/open/blue.png" alt="">:{{blue.toFixed(2)}}%</p>
					<p><img src="../../assets/images/open/hui.png" alt="">:{{gray.toFixed(2)}}%</p>
				</div>
				<van-tab title="箱内物品">
					<div class="Goods_list" >
						<div class="Goods_item" v-for="(item,index) in DetailList" :key="index" :class="item.lv | LvGuoLv" @click="goodItem(item)"  v-show="item.lv==1&& DetailList[0].lv==6 ?flags:true">
							<div class="probability">
								
							</div>
							<div class="Goods_pic" :style="{'background-image': 'url('+item.lv_bg_image+')'}">
								<img :src="item.cover" alt="">
							</div>
							<p class="text-overflow">{{item.name}}</p>

							<div class="" style="font-size: 12px;color: #FFF;margin-top:10px;margin-bottom:10px;"><span :style="item.name=='金球'?'display:none':''"><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</span></div>

						</div>
					</div>
				</van-tab>
				<van-tab title="最近掉落">
					<div class="history_list">
						<div class="historytitle">会员</div>
						<div class="historytitle">价值</div>
						<div class="historytitle">饰品名称</div>
						<!--  :style="{'background-image': 'url('+item.lv_bg_image+')'}" :style="{'background-image': `url(${require('../../assets/images/985/open/' + item.lv +'.png')})`}"-->
						<div class="history_item" v-for="(item,index) in historylist" :key="index" >
							<div class="history_pic">
								<img :src="item.user.avatar" alt="">
								<p>{{ item.user.name }}</p>
							</div>
							<div>
								<img src="@/assets/images/public/Gold.png" alt="" style="width:12px">{{ item.bean }}
							</div>
							<div>
								<p>{{item.name}}</p>
							</div>
						</div>
					</div>
				</van-tab>
				
			</van-tabs>
		</div>
	</div>
</template>

<script>
	import { UnpackingRecord } from '@/network/api.js'
	export default{
		name:'Goods',
		props:{
			DetailList:{//箱内物品列表
				type:Array
			},
			id:{//宝箱id
				type:Number
			}
		},
		data(){
			return{
				Switch:'2',//掉落/物品
				historylist:[],//历史掉落列表
				active: 0,
				flags:false,
				yellow:0,
				red:0,
				purple:0,
				blue:0,
				gray:0,

			}
		},
		created() {
			setTimeout(() => {
				this.GetUnpackingRecord()
			}, 8000)
		// console.log(this.DetailList);
		if(this.DetailList.length>0){
			this.yellow=0,
			this.red=0,
			this.purple=0,
			this.blue=0,
			this.gray=0
			setTimeout(() => {
				this.DetailList.forEach((item,index)=>{
					if(item.lv==1){
						this.yellow+=Number(item.odds)
					}
					if(item.lv==2){
						this.red+=Number(item.odds)
					}
					if(item.lv==3){
						this.purple+=Number(item.odds)
					}
					if(item.lv==4){
						this.blue+=Number(item.odds)
					}
					if(item.lv==5){
						this.gray+=Number(item.odds)
					}
				})
			}, 200);
		}
				
		},
		
		methods:{
			goodItem(item){
				if(item.lv==6){
					this.flags=!this.flags
				}
			},
			//历史掉落
			GetUnpackingRecord(){
				UnpackingRecord(this.id).then((res) => {
					this.historylist = res.data.data.historylist
				})
			}
		},
		filters: {
			LvGuoLv(val){
				if (val == '1') {
					return 'lv1'
				} else if (val == '2') {
					return 'lv2'
				} else if (val == '3') {
					return 'lv3'
				} else if (val == '4') {
					return 'lv4'
				}else if (val == '5') {
					return 'lv5'
				}else if (val == '6') {
					return 'lv6'
				}
			},
		},
		watch:{
			id(v){
				// console.log(v)
				this.GetUnpackingRecord()
			},
			DetailList(val){
				// console.log(this.DetailList);
				if(this.DetailList.length>0){
					this.yellow=0,
					this.red=0,
					this.purple=0,
					this.blue=0,
					this.gray=0
					setTimeout(() => {
						this.DetailList.forEach((item,index)=>{
							if(item.lv==1){
								this.yellow+=Number(item.odds)
							}
							if(item.lv==2){
								this.red+=Number(item.odds)
							}
							if(item.lv==3){
								this.purple+=Number(item.odds)
							}
							if(item.lv==4){
								this.blue+=Number(item.odds)
							}
							if(item.lv==5){
								this.gray+=Number(item.odds)
							}
						})
					}, 200);
				}
				
			}
		}
	}
</script>

<style  lang="scss">

	#Goods{

		box-sizing: border-box;
		.Goods_box{
			width: 100%;
			.van-tabs__wrap{
				height: 50px;
			}
			// margin-top: 50px;
			.van-tabs__nav--card .van-tab.van-tab--active {
				// background:  rgb(251, 250, 2);
				color: white;
				// background: url(https://d2topbox.com/_nuxt/img/xuanzhong.444aab0.png) no-repeat !important;
			}
			.van-tabs__nav{
				background: none;
				border: none;
				// border-bottom: 2px solid rgb(251, 250, 2);
			}
			.Explosive{
				width: 100%;
				display: flex;
				justify-content: space-around;
				align-items: center;
				background: url(../../assets/images/open/Explosive.png) no-repeat center;
				background-size: 100% 100%;
				height: 30px;
				color: white;
				width: 98%;
				margin: 5px auto;
				img{
					width: 10px;
				}
			}
			.van-tab--active{
				background: url('../../assets/images/985/home/44.png') no-repeat center !important;
				background-size: auto 100% !important;
			}
			.van-tabs__nav--card .van-tab {
				width: 50% !important;
				float: left;
				height: 50px;
				border: 0;
				color: #cccccc41;
				border-bottom: none;
				font-weight: 700;
				font-size: 12px;
				width: 120px;
				
				// background: #000;
				// background-size: 100% 100% !important;
				background-size:cover;
				// margin-left: 10px;

			}
			
			.van-tabs__nav{
				width: 100% !important;
				margin-left: 0;
				display: inline-block !important;
			}
			.Goods_nav{

				width: 100%;
				display: flex;
				color: #fff;
				margin-bottom: 10px;
				p{
					width: 80px;
					height: 30px;
					background: #fff;
					color: #000;
					text-align: center;
					line-height: 30px;
					font-size: 14px;
					&.active{
						background: #ffba2e;
						color: #fff;
					}
				}
			}

			.history_list{
				width: 100%;
				display: flex;
				padding: 5px 0;
				flex-wrap: wrap;
				background: rgba($color: #000000, $alpha: .2);
				border-radius: 0 4px 4px 4px;
				margin-top: -1px;
				justify-content: flex-start;
				background: #000;
				.historytitle{
					width: 33%;
					color: #fff;
					text-align: center;
					height: 50px;
					line-height: 50px;
					// background: rgb(22, 23, 37);
				}
				.history_item:nth-child(even){
					background: rgb(22, 23, 37);
				}
				.history_item{
					width: 100%;
					text-align: center;
					color: #fff;
					margin-bottom: 0.3rem;
					height: 3rem;
					background-size: 100% 100%;
					align-items: center;
					justify-content: space-evenly;
					// padding-top: 10px;
					display: flex;
					>div:nth-child(2){
						width: 50px;
					}
					>div:nth-child(3){
						width: 120px;
					}
					.history_pic{
						// width: 70%;
						// width: 10%;
						width: 33%;
						height:80px;
						display: flex;
						justify-content: center;
						align-items: center;
						// background-image: url(http://csgo.api.gameskins.cn/uploads/images/baeef9d5696b72118858f87ff955bee7.png);
						background-position: center;
						background-size: 80%;
						background-repeat: no-repeat;
						img{
							// max-width: 100%;
							width: 50px;
							vertical-align: middle;
							border-radius: 15px;
						}
					}
					p{
						width: 80%;
						padding: 0 3px;
						box-sizing: border-box;
						font-size: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin: 0 auto;
					}
				}
			}
			.Goods_list{
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				border-radius: 0 4px 4px 4px;
				justify-content: space-between;
				align-content: flex-start;
				&::after{
					display: block;
					content: '';
					width: 32.5%;
				}
				.Goods_item{
					&.lv1{
						background-size: 100% 100%;
						border-radius: 5px;
						border: 1px solid rgba(233, 212, 90, 0.358);
						background: linear-gradient(to top, rgba(255,0,0,0), rgb(139, 111, 18));
						background: url('../../assets/images/985/open/1.png') no-repeat center;
						background-size: 100% 100%;
					}
					&.lv2{
						background-size: 100% 100%;
						border-radius: 5px;
						// border: 1px solid rgb(210, 73, 73);
						background: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(133, 46, 46));
						background: url('../../assets/images/985/open/4.png') no-repeat center;
						background-size: 100% 100%;
					}
					&.lv3{
						background-size: 100% 100%;
						border-radius: 5px;
						border: 1px solid rgb(203, 70, 203);
						background: linear-gradient(to top, rgba(255,0,0,0), rgb(57,44,83));
						background: url('../../assets/images/985/open/3.png') no-repeat center;
						background-size: 100% 100%;
					}
					&.lv4{
						background-size: 100% 100%;
						border-radius: 5px;
						border: 1px solid rgb(86, 86, 214);
						background: linear-gradient(to top, rgba(255,0,0,0), rgb(57,79,102));
						background: url('../../assets/images/985/open/2.png') no-repeat center;
						background-size: 100% 100%;
					}
					&.lv5{
						background-size: 100% 100%;
						border-radius: 5px;
						border: 1px solid rgb(96, 96, 113);
						background: linear-gradient(to top, rgba(81, 72, 72, 0), rgb(87, 92, 104));
						background: url('../../assets/images/985/open/5.png') no-repeat center;
						background-size: 100% 100%;
					}
					&.lv6{
						background-size: 100% 100%;
						border-radius: 5px;
						border: 1px solid rgba(233, 212, 90, 0.358);
						background: linear-gradient(to top, rgba(255,0,0,0), rgb(139, 111, 18));
						background: url('../../assets/images/985/open/1.png') no-repeat center;
						background-size: 100% 100%;
					}
					width: 46%;
					// background: rgba($color: #000000, $alpha: .2);
					background-size: 100% 100% !important;
					// background-size: cover;
					border-radius: 4px;
					text-align: center;
					margin: 5px;
					background-position: -20px -20px;
					.probability{
						justify-content: space-between;
						display: flex;
						width: 100%;
						height: 30px;
						line-height: 25px;
						font-size: 10px;
						color: #fff;
						text-align: left;
						padding: 0 5px;
						box-sizing: border-box;
						& span:nth-child(1){
							color:#ECB105 ;
						}
						& span:nth-child(2){
							color:#dbdce8 ;
						}
					}
					.Goods_pic{
						width: 80%;
						height: 90px;
						margin: 0 auto;
						display: flex;
						justify-content: center;
						align-items: center;
						background-image: url(http://csgo.api.gameskins.cn/uploads/images/baeef9d5696b72118858f87ff955bee7.png);
						background-position: center;
						background-size: 80%;
						background-repeat: no-repeat;
						img{
							max-width: 80%;
							vertical-align: middle;
						}
					}
					p{
						font-size: 12px;
						color: #fff;
					}
					span{
						display: flex;
						justify-content: center;
						align-items: center;
						color: #f1bc60;
						margin: 5px 0;
						img{
							width: 16px;
						}
					}
					
				}
			}
		}
	}
	.Goods_box{
	// margin-top: 50px !important;
}
</style>
