<template>
  <div id="Dynamic">
    <div class="Dynamic_box">
      <div class="layer"></div>
      <div class="Dynamic_out">
        <div class="container" :style="LeftSty" :class="TranSitionSty">
          <div
            class="contItem"
            :style="
              'width:' +
              WidTh +
              'px;margin-left:' +
              MarGin +
              'px;margin-right:' +
              MarGin +
              'px'
            "
            v-for="(item, index) in DetailArr"
            :key="index"
            :src="item.lv | GuoLvTwo"
          >
            <div
              class="cont_pic"
            >
              <img :src="item.cover" alt="" />
            </div>
            <div class="item-bottom" :class="`bg-${item.lv} show-${item.lv}`"></div>
          </div>
        </div>
      </div>
      <div class="shu">

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dynamic",
  props: {
    DetailList: {
      //箱内物品
      type: Array,
    },
    DetailData: {
      //奖品列表
      type: Array,
    },
    Dynamic_checkeds: {
      //动画播放
      type: String,
    },
    Dynamic_num: {
      //奖品数量
      type: Number,
    },
  },
  created(){
    // console.log(this.DetailList);
    this.Arraysplicing()
  },
  data() {
    return {
      LeftSize: "0", //偏移距离
      TranSition: "active", //过度曲线
      DetailArr: [], //箱内物品(重置后)
      also: 0, //假数据(判断是否继续处理)
      process: new Audio(require("@/assets/audio/booms.mp3")), //开奖过程声音
      WidTh:76,
			MarGin:10,
      J_arr:[],
      boom: new Audio(require('@/assets/audio/boom.mp3')), //开箱结束声音
    };
  },
  filters: {
			GuoLv(val) {
				if (val == '1') {
					return 'color:#e6ba1c'
				} else if (val == '2') {
					return 'color:#d32ce6'
				} else if (val == '3') {
					return 'color:#834fff'
				} else if (val == '4') {
					return 'color:#4b69ff'
				} else {
					return 'color:#bbbbbb'
				}
			},
			GuoLvTwo(val){
				if (val == '1') {
					return require('@/assets/images/Swiper/Lv1.png')
				} else if (val == '2') {
					return require('@/assets/images/Swiper/Lv2.png')
				} else if (val == '3') {
					return require('@/assets/images/Swiper/Lv3.png')
				} else if (val == '4') {
					return require('@/assets/images/Swiper/Lv4.png')
				} else {
					return require('@/assets/images/Swiper/Lv5.png')
				}
			}
		},
  methods: {
    //拼接并打乱数组
    Arraysplicing() {
      let A_Arr = [];
      this.J_arr=this.DetailList.filter((item) => {
        return item.lv==1
      }) 
      for (let i = 0; i < 20; i++) {
        A_Arr = A_Arr.concat(this.J_arr);
      }
      
      // console.log(A_Arr)

      //打乱
      function randSort(A_Arr) {
        for (let i = 0, len = A_Arr.length; i < len; i++) {
          let rand = parseInt(Math.random() * len);
          let temp = A_Arr[rand];
          A_Arr[rand] = A_Arr[i];
          A_Arr[i] = temp;
        }
        return A_Arr;
      }

      this.DetailArr = randSort(A_Arr);
      // console.log(this.DetailArr)
    },

    //处理中奖
    Determine() {
     
        // console.log(this.DetailData);
            // console.log('Determine调用')
          setTimeout(() => {
            if (this.also < this.Dynamic_num) {
            this.DetailArr[32] = this.DetailData[this.also]; //奖品挪移
            this.TranSition = "active"; //增加过度动画
            this.LeftSize = -(
              this.WidTh * 31 +
              this.MarGin * 2 * 31 -
              this.MarGin * 2
            ); //偏移值
            this.process.currentTime = 0;
 
            this.process.play(); //播放声音
            this.also += 1; //自加1
            setTimeout(() => {
              this.IsRepeat();
            }, 6900);
          } else {
            //子传父(动画结束)
            this.$emit("setAnimation", true);
          
            // this.boom.play()
          }
        }, 100);
    },

    //重复
    IsRepeat() {
      // console.log('Repeat调用')
      
      this.$emit("setEquipment", this.also);
      let alsoArr = [];
      alsoArr.unshift(
        this.DetailArr[31],
        this.DetailArr[32],
        this.DetailArr[33]
      ); //上次偏移到最后的三件物品
      this.Arraysplicing(); //重新调用打乱
      this.DetailArr = alsoArr.concat(this.DetailArr); //上次最后的三件物品插入新打乱的数组
      this.TranSition = ""; //清掉过度动画
      this.LeftSize = "0"; //偏移值复原
      setTimeout(() => {
        this.Determine();
      }, 100);
    },
  },

  computed: {
    //偏移样式
    LeftSty() {
      return "transform: translateX(" + this.LeftSize + "px)";
    },
    //过渡样式
    TranSitionSty() {
      return this.TranSition;
    },
  },

  watch: {
    DetailList(arr) {
      // console.log(arr)
      if (arr.length > 0){
          this.Arraysplicing();
      }
    },

    //为'1'处理中奖数据并播放动画
    Dynamic_checkeds(str) {
      this.also = 0;
      if (str == "1") this.Determine();
    },
  },
};
</script>

<style lang="scss" scoped>
#Details{
		padding: 35px 0;
		margin-top: -15px;
		box-sizing: border-box;
		
		.Details_box{
			width: 100%;
			height: 200px;
			margin: 0 auto;
			background-size: contain;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin: 0 auto;
			.Details_box_pic{
				width: 100%;
				height: 250px;
				margin-right: 5px;
				background-position: center 10px;
				display: flex;
				background-size: contain;
				background-repeat: no-repeat;
				z-index: 9;
				&.active{
					display: flex;
					justify-content: center;
					align-items: center;
				}
				img{
					max-width: 50%;
					vertical-align: middle;
					margin: 0 auto;
					z-index: 9;
				}
				.dao{
					max-height: 60% !important;
					margin-top: 1rem;
				}
			}
			.Details_Price{
				z-index: 9;
				font-size: 16px;
				color: #f1bc60;
				z-index: 9;
				margin-bottom: -1.5rem;
				margin-top: 1rem;
				img{
					width: 20px;
					height: 20px;
					vertical-align: middle;
					margin-bottom: 2px;
				}
			}
		}
	}
	#Dynamic {
		padding: 10px;
		
    height: 100%;
		box-sizing: border-box;
    margin:auto auto !important;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    // z-index: 9;
		.Dynamic_box {
			width: 98%;
			height: 200px;
			margin: 0 auto;
			overflow: hidden;
			position: relative;
      z-index: 1;
      .shu{
				width: 0px;
				height: 82px;
				position: absolute;
				left: 50%;
				// background: #fff;
				border: 1px  rgb(122, 95, 189) dashed;
				z-index: 9999;
				top: 17px;
			}
			.layer {
				position: absolute;
				left: 0;
				right: 0;
				width: 100%;
				height: 120px;
				background: url('../../../assets/images/q2/luckyin/bac.png') no-repeat center;
        background-size: 100% 100%;
        background-position: center center;
				z-index: 1;
			}
      .Dynamic_out {
          padding: 4px 0;
          overflow: hidden;
          // mask-image: linear-gradient(90deg, rgba(25, 25, 25, 0) 4%, #000 50%, rgba(25, 25, 25, 0) 96%);
          // -webkit-mask-image: linear-gradient(90deg, rgba(25, 25, 25, 0) 4%, #000 50%, rgba(25, 25, 25, 0) 96%);
          position: relative;
          // mask-image:linear-gradient(90deg,rgba(25,25,25,0) 4%,#000 50%,rgba(25,25,25,0) 96%);
          // -webkit-mask-image:linear-gradient(90deg,rgba(25,25,25,0) 4%,#000 50%,rgba(25,25,25,0) 96%);
        }
			.Dynamic_out {
				width: 90%;
				height: 90px;
				margin: 14px auto;
				// border-radius: 40px;
				overflow: hidden;
				// mask-image:linear-gradient(90deg,rgba(25,25,25,0) 4%,#000 50%,rgba(25,25,25,0) 96%);
				// -webkit-mask-image:linear-gradient(90deg,rgba(25,25,25,0) 4%,#000 50%,rgba(25,25,25,0) 96%);
				.container {
					height: 100%;
					display: flex;
          margin-top: 10px;
					&.active{
						transition: 6s cubic-bezier(0, 0, 0.28, 1);
					}
					.contItem {
						height: 60px;
						margin-top: 6px;
						margin-bottom: 6px;
						// background: url(../../../assets/images/public/pub_back.png) no-repeat center;
						background-size: 100% 100%;
						border-radius: 6px;
            .item-bottom{
              width: 100%;
              height: 5%;
              bottom: 0;
              position: relative;
              bottom: 0;
            }
						.cont_pic {
							width: 76px;
							height: 60px;
							// background: url(http://www.gameskins.cn/img/LV4.9815642e.png);
							background-repeat: no-repeat;
							background-size: 60%;
							background-position: center;
							display: flex;
							justify-content: center;
							align-items: center;

							img {
								vertical-align: middle;
								max-width: 100%;
								max-height: 100%;
							}
						}
					}
				}
			}
		}
	}
</style>
